
import { Component, Prop } from "vue-property-decorator";
import { getComponent, getConfigEnv, jsonParse } from "@/utils/helpers";
import { mixins } from "vue-class-component";
import AWS from "aws-sdk";
import {
  POST_SHAPEFILE_LAMBDA_REGION,
  IDENTITY_POOL_REGION,
  IDENTITY_POOL_ID,
} from "@/lib/constants";
import DataLoading from "../../lib/mixins/dataLoading";
import TasqJob from "../../interfaces/tasqs/TasqJob";
import setpointV2Module from "@/store/modules/setpointV2Module";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";
import metaDataModule from "@/store/modules/metaDataModule";
import tasqsListModule from "@/store/modules/tasqsListModule";
import workflowModule from "@/store/modules/workflowModule";

@Component({
  components: {
    SpinnerLoader: () => getComponent("loaders/SpinnerLoader"),
    LineChart: () => import("@/lib/charts/lineChart"),
  },
})
export default class SetpointFeedbackV3 extends mixins(DataLoading) {
  @Prop({ required: true }) tasq!: TasqJob;

  isLoadingPlot = false;

  showTpLpPopup = false;

  showTpPopup = false;

  acceptedValue = "";

  message = ''

  isSavingData = false;

  get isValidForm() {
    if (this.acceptedValue) {
      return true;
    }
    return false;
  }

  created(){
    console.log(this.tasq.setpointAccepted);
    if(this.tasq && this.tasq.setpointAccepted === true){
      this.message = 'Setpoint Accepted!'
    }else  if(this.tasq && this.tasq.setpointAccepted === false){
      this.message = 'Setpoint Rejected!'
    }
  }

  roundToInteger(number) {
    if(number){
      return Math.round(number);
    }
      return number;
  }

  download(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  downloadChart() {
    this.isLoadingPlot = true;
    AWS.config.update({
      region: IDENTITY_POOL_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IDENTITY_POOL_ID,
      }),
    });
    const lambda = new AWS.Lambda({
      region: POST_SHAPEFILE_LAMBDA_REGION,
      apiVersion: "2015-03-31",
    });

    console.log(
      JSON.stringify({
        // @ts-ignore
        Operator: getConfigEnv("OPERATOR_LOWERCASED"),
        // @ts-ignore
        NodeID: this.tasq.wellName,
        DecidedBy: this.tasq.setpointRecord.decided_by,
      })
    );

    console.log(`tasq-workflow-${getConfigEnv("STAGE")}-GetFullPlotFileSyncSource`);

    const pullParams = {
      FunctionName: `tasq-workflow-${getConfigEnv("STAGE")}-GetFullPlotFileSyncSource`,
      InvocationType: "RequestResponse", // Event | RequestResponse | DryRun
      LogType: "None",
      Payload: JSON.stringify({
        // @ts-ignore
        Operator: getConfigEnv("OPERATOR_LOWERCASED"),
        // @ts-ignore
        NodeID: this.tasq.wellName,
        DecidedBy: this.tasq.setpointRecord.decided_by,
      }),
    };
    lambda.invoke(pullParams, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        // @ts-ignore
        const parsed_payload = JSON.parse(data.Payload);
        const parsed_html = JSON.parse(parsed_payload.Results);
        this.download(`setpoint_rec_${this.tasq.wellName}.html`, parsed_html.data);
        this.isLoadingPlot = false;
      }
    });
  }



  async fetchWellHistory(){
      let promises: any = []
    if (this.tasq.workflowTasqId != null) {
        promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.workflowTasqId));
      } else if (this.tasq.scheduledJobID != null) {
        promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.scheduledJobID));
      }

      promises.push(workflowModule.getWellHistory(this.tasq.wellName));

      Promise.all(promises).then(
        () => {
          // console.log('resolved handle step v1');
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log('Error:');
          // eslint-disable-next-line no-console
          console.log(err);
        }
      );
  }

  async updateSetpoints() {
    this.isSavingData = true;

    try {
      let accepted = true;
      if (this.acceptedValue === "Reject") {
        accepted = false;
      }
      await setpointV2Module.postSetpointJobResponse({
        predictionID: this.tasq.id,
        workflowId: this.tasq.workflowTasqId,
        accepted: accepted,
      });
      tasqFeedbackModule.setResponseSelectionAction("NO");

      const jobFormResponsePayload = {
        predictionID: this.tasq.id,
        workflowTaskID: this.tasq.workflowTasqId,
        completeForToday: true,
        closeTasq: false,
        formResponse: [],
        nodeID: this.tasq.wellName,
        validationResponse: tasqFeedbackModule.validationResponse,
      };

      await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
      await tasqsListModule.getSubmittedForms();
      let newTasq = await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
      await this.fetchWellHistory()
      if(accepted){
        this.message = 'Setpoint Accepted!'
      }else{
        this.message = 'Setpoint Rejected!'
      }
      this.acceptedValue = "";
      this.$tasqAlert({
        title: "Success",
        message: this.message,
        type: "success",
      });
    } catch (error) {
      this.$tasqAlert({
        title: "Error",
        message: "Setpoint update failed! ",
        type: "error",
      });
    }
    this.isSavingData = false;
  }
}
